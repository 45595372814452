<template>
<main>
    <page-header title="Contratos Eps"></page-header>
    <div class="container-fluid mt-n10">
        <div class="card">
            <div class="card-body">
                <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                    <template #grid>
                        <grid-table>
                            <template #head>
                                <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                            </template>
                            <template #body>
                                <grid-row v-for="(contract, i) in pagination.data" :key="`d_${i}`">
                                    <grid-cell>
                                        <p class="mb-0">{{contract.codigo}}</p>
                                        <p class="mb-0 small">{{contract.descripcion}}</p>
                                        <p class="small mb-0 text-muted">{{contract.eps.IDEPS}} - {{contract.eps.DESEPS}}</p>
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{contract.fecha_inicio | formatDate}} - {{contract.fecha_final | formatDate}}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{contract.tipo_afiliacion.descripcion}}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{contract.tipo_contrato}}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{contract.modalidad}}
                                    </grid-cell>
                                    <grid-cell class="text-center">
                                        {{contract.tarifa.nombre}}
                                    </grid-cell>
                                    <grid-cell class="text-center">
                                        {{contract.descuento}} %
                                    </grid-cell>
                                    <grid-cell>
                                        <router-link
                                            class="btn btn-transparent btn-icon"
                                            :to="{'name' : 'contratos-eps.edit', params: {id : contract.id}}">
                                            <i class="fas fa-edit"></i>
                                        </router-link>
                                    </grid-cell>
                                </grid-row>
                            </template>
                        </grid-table>
                    </template>
                    <template #action-button>
                        <router-link class="btn btn-info btn-sm shadow-sm mb-2" :to="{name:'contratos-eps.create'}">
                            <i class="fa fa-plus"></i> Nuevo
                        </router-link>
                    </template>
                </data-grid>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import PageHeader from "../../../components/layouts/content/page-header";
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import ContratosEpsService from "../../../services/contratosEpsService";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";
import Swal from 'sweetalert2';

export default {
    components: {GridCell, GridRow, GridCellHeader, GridTable, PageHeader, DataGrid},
    data () {
       return {
           headers : ['Contrato', 'Periodo','Afiliacion', 'Tipo', 'Modalidad', 'Tarifa', 'Descuento', ''],
           pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               total : 0,
               search : '',
           }
       }
    },
    created() {
        this.index();
    },
    methods : {
        async index () {
            try{
                this.LoaderSpinnerShow();

                const response  = (await ContratosEpsService.index(this.pagination.search, this.pagination))?.data;

                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;

                this.LoaderSpinnerHide();

            }catch (e) {
                console.error(e);
                Swal.fire('Ha ocurrido un error al procesar la peticion','', 'error');
            }

        },
        changePage(page) {
            this.pagination.page = page;
            this.index();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.index();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.index();
        },
    }
}
</script>
